/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { Provider_v1 } from 'src/dto/eob/provider-v1';
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI
} from '../dto';
import { ObservableItemTypes } from './itemtypes';

export interface ObservableInventory_v1 extends DTO, DTOMethods<ObservableInventory_v1> {
    itemEntryDate: Date;
    itemExpireDate: Date;
    organization: DTORef;
    costCenter: DTORef;
    rateGroup: DTORef;
    application: DTORef;
    footprint: DTORef;
    logicalEnvironment: DTORef;
    dynatraceEnvironment: DTORef;
    dynatraceCluster: DTORef;

    dynatraceEntityId?: string;
    dynatraceEntityName?: string;
    dynatraceEntityDisplayName?: string;

    provider: Provider_v1;
    region: DTORef;
    location: DTORef;
    networkZone: DTORef;

    providerType?: string;
    providerName?: string;
    providerIcon?: string;
    cloudId?: string;
    cloudName?: string;
    regionName?: string;
    locationName?: string;
    locationIdx?: string;
    avbZoneName?: string;
    tierName?: string;

    organizationName?: string;
    organizationIdx?: string;
    costcenterName?: string;
    costcenterIdx?: string;
    rateGroupName: string;
    rateGroupIdx?: string;

    applicationName?: string;
    applicationIdx?: string;
    footprintName?: string;
    footprintIdx?: string;
    logicalEnvironmentId?: number;
    logicalEnvironmentName?: string;

    dynatraceEnvironmentId?: string;
    dynatraceEnvironmentName?: string;
    dynatraceClusterId?: string;
    dynatraceClusterName?: string;

    purpose: string;
    hostOS: string;
    hostOSVersion: string;
    hostGroup: string;
    automationData: string;
    memoryTotal: number;
    cpuCoresTotal: number;
    importSource: string;
    subscriptionName: string;
    resourceGroup: string;
    ownerEmail: string;
    appAlertGroup: string;
    computeAlertGroup: string;
    storageAlertGroup: string;
    networkAlertGroup: string;
    codeModules: number;
    mainframeTPS: number;
    itemCount: number;
    itemType: ObservableItemTypes;
    platform?: DTORef;
    itemPartition: string;

    actualMonHostType: string;
    actualMonLogType: string;

    monitoringHostType: string;
    monitoringHostVolume: number;
    monitoringLogType: string;
    monitoringLogVolume: number;
    monitoringRUMType: string;
    monitoringRUMVolume: number;
    monitoringSesRecType: string;
    monitoringSesRecVolume: number;
    monitoringAppSecType: string;
    monitoringAppSecVolume: number;
    monitoringRUMPropVolume: any;
    monitoringRUMPropType: any;
    monitoringClickpathType: any;
    monitoringClickpathVolume: any;
    monitoringSynthAPIType: any;
    monitoringSynthAPIVolume: any;
    monitoringHTTPMonType: any;
    monitoringHTTPMonVolume: any;

    hostDailyCount: number;
    dailyFullstack: number;
    dailyInfra: number;
    dailyAppSec: number;
    dailyDem: number;
    dailyDdu: number;
    dailyZos: number;
    dailyGrail: number;
    requirements: DTORef[];
    allocation: DTORef | number;
    unallocated?: boolean;
}
export const ObservableInventory_v1: DTOMetaData & DTOClassMethods<ObservableInventory_v1> = {
    _label:'Observable Environments',
    _dto: 'ObservableInventory_v1',
    _type: 'observable-inventory',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Item entry date', prop: 'itemEntryDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined },
        { label: 'Item expire date', prop: 'itemExpireDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined },
        { label: 'Purpose', prop: 'purpose', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Item tags', prop: 'itemTags', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Cost center name', prop: 'costCenterName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Cost center idx', prop: 'costCenterIdx', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Cost center', prop: 'costCenter', hint: 'Costcenter', type: 'dto', fixed: true, source: '/api/eob/v1.0/costcenter/v1.0', values: undefined },
        { label: 'Application name', prop: 'applicationName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Application idx', prop: 'applicationIdx', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Application', prop: 'application', hint: 'Application', type: 'dto', fixed: true, source: '/application/v1.0', values: undefined },
        { label: 'Organization name', prop: 'organizationName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Organization idx', prop: 'organizationIdx', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Organization', prop: 'organization', hint: 'Organization', type: 'dto', fixed: true, source: '/', values: undefined },
        { label: 'Logical environment name', prop: 'logicalEnvironmentName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Logical environment', prop: 'logicalEnvironment', hint: 'Logical environment', type: 'dto', fixed: true, source: '/logical-environment/v1.0', values: undefined },
        { label: 'Dynatrace entity id', prop: 'dynatraceEntityId', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Dynatrace environment name', prop: 'dynatraceEnvironmentName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Dynatrace environment', prop: 'dynatraceEnvironment', hint: 'Dynatrace environment', type: 'dto', fixed: true, source: '/dynatrace-environment/v1.0', values: undefined },
        { label: 'Dynatrace cluster name', prop: 'dynatraceClusterName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Provider name', prop: 'providerName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Region name', prop: 'regionName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Location name', prop: 'locationName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Location idx', prop: 'locationIdx', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Avb zone name', prop: 'avbZoneName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Tier name', prop: 'tierName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Network zone', prop: 'networkZone', hint: 'Networkzone', type: 'dto', fixed: true, source: '/networkzone/v1.0', values: undefined },
        { label: 'Host o s', prop: 'hostOS', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Host o s version', prop: 'hostOSVersion', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Host group', prop: 'hostGroup', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Automation data', prop: 'automationData', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Memory total', prop: 'memoryTotal', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Cpu cores total', prop: 'cpuCoresTotal', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Import source', prop: 'importSource', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Subscription name', prop: 'subscriptionName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Resource group', prop: 'resourceGroup', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Owner email', prop: 'ownerEmail', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'App alert group', prop: 'appAlertGroup', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Compute alert group', prop: 'computeAlertGroup', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Storage alert group', prop: 'storageAlertGroup', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Network alert group', prop: 'networkAlertGroup', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Code modules', prop: 'codeModules', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Mainframe t p s', prop: 'mainframeTPS', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Item count', prop: 'itemCount', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Item type', prop: 'itemType', hint: 'Item types', type: 'enum', fixed: false, source: undefined, values: ["HOST", "CONTAINER", "OTHER", "FUNCTION", "LPAR", "CICSREGION"] },
        { label: 'Item partition', prop: 'itemPartition', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring host type', prop: 'monitoringHostType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring host volume', prop: 'monitoringHostVolume', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring log type', prop: 'monitoringLogType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring log volume', prop: 'monitoringLogVolume', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring r u m type', prop: 'monitoringRUMType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring r u m volume', prop: 'monitoringRUMVolume', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring ses rec type', prop: 'monitoringSesRecType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring ses rec volume', prop: 'monitoringSesRecVolume', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring app sec type', prop: 'monitoringAppSecType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Monitoring app sec volume', prop: 'monitoringAppSecVolume', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Host daily count', prop: 'hostDailyCount', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily fullstack', prop: 'dailyFullstack', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily infra', prop: 'dailyInfra', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily app sec', prop: 'dailyAppSec', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily dem', prop: 'dailyDem', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily ddu', prop: 'dailyDdu', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily zos', prop: 'dailyZos', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Daily grail', prop: 'dailyGrail', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Allocation', prop: 'allocation', hint: 'Observability allocation', type: 'dto', fixed: true, source: '/observability-allocation/v1.0', values: undefined },
     ],
    _related: [
        { prop: 'networkZone', type: 'networkzone', class: 'Networkzone_v1', label: 'Network Zone' },
        { prop: 'location', type: 'location', class: 'Location_v1', label: 'Location' },
        { prop: 'region', type: 'region', class: 'Region_v1', label: 'Region' },
        { prop: 'provider', type: 'provider', class: 'Provider_v1', label: 'Provider' },

        { prop: 'application', type: 'application', class: 'Application_v1', label: 'Application' },
        { prop: 'footprint', type: 'footprint', class: 'Footprint_v1', label: 'Footprint' },
        { prop: 'logicalEnvironment', type: 'logical-environment', class: 'LogicalEnvironment_v1', label: 'Logical Environment' },
        { prop: 'dynatraceEnvironment', type: 'dynatrace-environment', class: 'DynatraceEnvironment_v1', label: 'Dynatrace Environment' },

        { prop: 'requirements', type: 'unknown', class: 'Unknown', label: 'Requirements' },
     ],
    _endpoint: '/api/eob/v1.0/observable-inventory/v1.0',
    _docLink: '/wiki/elevate/Assets/observable-inventory/',
    _icon: './assets/dtos/observable-inventory.svg',

    endpointAPI: new BoundEndpointAPI<ObservableInventory_v1>('ObservableInventory_v1', DTO),
    from: (obj: any): ObservableInventory_v1 => {
        return Object.setPrototypeOf(obj, ObservableInventory_v1._prototype);
    },
    select: (p: any = ''): any => {
        return ObservableInventory_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => ObservableInventory_v1.from(a) as ObservableInventory_v1)
            : ObservableInventory_v1.from(res)
    )}
}
